import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import './App.scss';
import { lazy, Suspense } from 'react';
import importRetry from './importRetry';
import lazyWithRetry from './lazyWithRetry';
import 'react-toastify/dist/ReactToastify.css';
import Screenloader from './components/screenLoader/Screenloader';
import { QueryClientProvider } from '@tanstack/react-query';
import { QueryCache, QueryClient } from '@tanstack/react-query';

export const QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (_error, query) => {
      if (query.state.data !== undefined) {
        console.error(`Something went wrong`);
      }
    },
  }),
});

const ToastContainer = lazy(() =>
  import('react-toastify').then(({ ToastContainer }) => ({
    default: ToastContainer,
  }))
);
const OnboardProcess = lazyWithRetry(() =>
  importRetry(() => import('./pages/OnboardProcess/OnboardProcess'))
);
const Inprocess = lazyWithRetry(() =>
  importRetry(() => import('./pages/inprocess/Inprocess'))
);
const LeaveManagement = lazyWithRetry(() =>
  importRetry(() => import('./components/LeaveManagement/LeaveManagement'))
);
const Employees = lazyWithRetry(() =>
  importRetry(() => import('./components/employes/Employees'))
);
const EmployeeStatus = lazyWithRetry(() =>
  importRetry(() => import('./components/LeaveCard/SlackStatusDetails'))
);
const Dashboard = lazyWithRetry(() =>
  importRetry(() => import('./pages/dashboard/Dashboard'))
);
const PageNotFound = lazyWithRetry(() =>
  importRetry(() => import('./pages/PageNotFound/PageNotFound'))
);
const Profile = lazyWithRetry(() =>
  importRetry(() => import('./pages/Profile/Profile'))
);
const CompanyDocs = lazyWithRetry(() =>
  importRetry(() => import('./pages/CompanyDocs/CompanyDocs'))
);
const PayslipGenerate = lazyWithRetry(() =>
  importRetry(() => import('./pages/PayslipGenerate/PayslipGenerate'))
);
const AttendancePortal = lazyWithRetry(() =>
  importRetry(() => import('./pages/AttendancePortal/UpdateToNewPortal'))
);
const AttendanceList = lazyWithRetry(() =>
  importRetry(() => import('./pages/AttendanceList/AttendanceList'))
);
const AttendenceDetail = lazyWithRetry(() =>
  importRetry(() => import('./pages/AttendanceList/ViewAttendenceDetails'))
);
const UsersOnline = lazyWithRetry(() =>
  importRetry(() => import('./pages/AttendanceList/UsersOnline'))
);
const Regularize = lazyWithRetry(() =>
  importRetry(() => import('./pages/RegularizeAttendance/RegularizeAttendance'))
);
const HR = lazyWithRetry(() => importRetry(() => import('./pages/HR/HR')));
const PrivateRoute = lazyWithRetry(() =>
  importRetry(() => import('./privateRoutes/privateRoute'))
);

const CheckAuthRoute = lazyWithRetry(() =>
  importRetry(() => import('./privateRoutes/checkAuthRoute'))
);
const DashboardDefault = lazyWithRetry(() =>
  importRetry(() => import('./components/DashboardDefault/DashboardDefaut'))
);
const LoginPage = lazyWithRetry(() =>
  importRetry(() => import('./pages/LoginPage/LoginPage'))
);
const ChangePasswordPage = lazyWithRetry(() =>
  importRetry(() => import('./pages/ChangePasswordPage/ChangePasswordPage'))
);
const ForgotPasswordPage = lazyWithRetry(() =>
  importRetry(() => import('./pages/ForgotPasswordPage/ForgotPasswordPage'))
);
const ResetPasswordConfirmation = lazyWithRetry(() =>
  importRetry(
    () => import('./pages/PasswordResetConfirmation/PasswordResetConfirmation')
  )
);
const ResetPassword = lazyWithRetry(() =>
  importRetry(() => import('./pages/ResetPassword/ResetPassword'))
);
const AssignRoles = lazyWithRetry(() =>
  importRetry(() => import('./components/Admin/AssignRoles/AssignRoles'))
);
const UpdatePermissions = lazyWithRetry(() =>
  importRetry(() => import('./pages/Admin/UpdatePermissions'))
);
const MonthlyView = lazyWithRetry(() =>
  importRetry(() => import('./pages/AttendanceList/MonthlyView'))
);

const SlackStatus = lazyWithRetry(() =>
  importRetry(() => import('./pages/SlackStatus/SlackStatus'))
);

const Occasions = lazyWithRetry(() =>
  importRetry(() => import('./pages/LeavesAndOccasions/Occasions'))
);

const LeaveHistory = lazyWithRetry(() =>
  importRetry(() => import('./pages/LeavesAndOccasions/LeaveHistory'))
);

const App: React.FC = () => {
  return (
    <QueryClientProvider client={QUERY_CLIENT}>
      <div className="App">
        <Router>
          <Suspense fallback={<Screenloader />}>
            <Routes>
              <Route
                path="/"
                element={
                  <Suspense fallback={<Screenloader />}>
                    <CheckAuthRoute>
                      <LoginPage />
                    </CheckAuthRoute>
                  </Suspense>
                }
              />
              <Route
                path="/changePassword"
                element={
                  <Suspense fallback={<Screenloader />}>
                    <ChangePasswordPage />
                  </Suspense>
                }
              />
              <Route
                path="/forgotpassword"
                element={
                  <Suspense fallback={<Screenloader />}>
                    <ForgotPasswordPage />
                  </Suspense>
                }
              />
              <Route
                path="/reset-password/:token"
                element={
                  <Suspense fallback={<Screenloader />}>
                    <ResetPassword />
                  </Suspense>
                }
              />
              <Route
                path="/password-reset-confirmation"
                element={
                  <Suspense fallback={<Screenloader />}>
                    <ResetPasswordConfirmation />
                  </Suspense>
                }
              />
              <Route
                path="dashboard/*"
                element={
                  <Suspense fallback={<Screenloader />}>
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  </Suspense>
                }
              >
                <Route
                  path="dashboard_default"
                  element={
                    <PrivateRoute>
                      <DashboardDefault />
                    </PrivateRoute>
                  }
                />
                <Route path="admin/*">
                  <Route path="" element={<Navigate to="assign-roles" />} />
                  <Route path="assign-roles" element={<AssignRoles />} />
                  <Route
                    path="update-permissions"
                    element={<UpdatePermissions />}
                  />
                </Route>
                <Route
                  path="employees"
                  element={
                    <Suspense fallback={<Screenloader />}>
                      <PrivateRoute>
                        <Employees />
                      </PrivateRoute>
                    </Suspense>
                  }
                />
                <Route path="leavesAndOccasions/*">
                  <Route path="" element={<Navigate to="occasions" />} />
                  <Route path="occasions" element={<Occasions />} />
                  <Route path="leaveHistory" element={<LeaveHistory />} />
                </Route>
                <Route
                  path="leaveManagement"
                  element={
                    <Suspense fallback={<Screenloader />}>
                      <PrivateRoute>
                        <LeaveManagement />
                      </PrivateRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="slackStatus"
                  element={
                    <Suspense fallback={<Screenloader />}>
                      <PrivateRoute>
                        <SlackStatus />
                      </PrivateRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="companydocs"
                  element={
                    <Suspense fallback={<Screenloader />}>
                      <PrivateRoute>
                        <CompanyDocs />
                      </PrivateRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="status/:statusId"
                  element={
                    <Suspense fallback={<Screenloader />}>
                      <PrivateRoute>
                        <EmployeeStatus />
                      </PrivateRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="payslip-generate"
                  element={
                    <Suspense fallback={<Screenloader />}>
                      <PrivateRoute>
                        <PayslipGenerate />
                      </PrivateRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="mark-attendance"
                  element={
                    <Suspense fallback={<Screenloader />}>
                      <PrivateRoute>
                        <AttendancePortal />
                      </PrivateRoute>
                    </Suspense>
                  }
                />
                <Route path="attendance-list/*">
                  <Route
                    path=""
                    element={
                      <Suspense fallback={<Screenloader />}>
                        <PrivateRoute>
                          <AttendanceList />
                        </PrivateRoute>
                      </Suspense>
                    }
                  />
                  <Route
                    path="monthly-view"
                    element={
                      <Suspense fallback={<Screenloader />}>
                        <PrivateRoute>
                          <MonthlyView />
                        </PrivateRoute>
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="view-attedence/:employeeId"
                  element={
                    <Suspense fallback={<Screenloader />}>
                      <PrivateRoute>
                        <AttendenceDetail />
                      </PrivateRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="online-users"
                  element={
                    <Suspense fallback={<Screenloader />}>
                      <PrivateRoute>
                        <UsersOnline />
                      </PrivateRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="regularize-attendance"
                  element={
                    <Suspense fallback={<Screenloader />}>
                      <PrivateRoute>
                        <Regularize />
                      </PrivateRoute>
                    </Suspense>
                  }
                />
                <Route
                  path="payroll"
                  element={
                    <Suspense fallback={<Screenloader />}>
                      <PrivateRoute>
                        <HR />
                      </PrivateRoute>
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="profile"
                element={
                  <Suspense fallback={<Screenloader />}>
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  </Suspense>
                }
              />
              <Route
                path="/onboard"
                element={
                  <Suspense fallback={<Screenloader />}>
                    <OnboardProcess />
                  </Suspense>
                }
              />
              <Route
                path="/inprocess"
                element={
                  <Suspense fallback={<Screenloader />}>
                    <Inprocess />
                  </Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <Suspense fallback={<Screenloader />}>
                    <PageNotFound />
                  </Suspense>
                }
              />
            </Routes>
          </Suspense>
        </Router>
        <Suspense fallback={<Screenloader />}>
          <ToastContainer />
        </Suspense>
      </div>
    </QueryClientProvider>
  );
};

export default App;
